import { PageProps } from "gatsby";
import React, { ReactElement } from "react";
import ScrollablePage, { Position } from "./components/ScrollablePage";
import { URL_PATHS } from "./utils/globals";
import { useLogVersion } from "./utils/hooks";

type PathMatchMethod = "startsWith";

interface PositionMatcher extends Position {
  pathMatchMethod?: PathMatchMethod;
}

interface PathPositions {
  [path: string]: PositionMatcher;
}

const PATHS_POSITIONS: PathPositions = {
  "/": {
    x: 0,
    y: 0,
  },
  [URL_PATHS.PROJECTS]: {
    x: 0,
    y: -1,
  },
  [URL_PATHS.BIO]: {
    x: 0,
    y: 1,
  },
  [URL_PATHS.PROJECT]: {
    x: 1,
    y: -1,
    pathMatchMethod: "startsWith",
  },
};

interface PathClasses {
  [key: string]: string;
}

const PATHS_CLASSES: PathClasses = {
  "/progetti/": "page-project",
};

function getPathPosition(path: string) {
  const foundPath = Object.entries(PATHS_POSITIONS).find(
    ([pathMatcher, pathPosition]) => {
      const pathMatchMethod = pathPosition.pathMatchMethod || "equals";
      if (pathMatchMethod === "equals") {
        return pathMatcher === path;
      } else if (pathMatchMethod === "startsWith") {
        return path.startsWith(pathMatcher);
      }
    }
  )?.[0];
  const position = foundPath ? PATHS_POSITIONS[foundPath] : { x: 0, y: 0 };
  return position;
}

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({
  element,
  props,
}: {
  element: ReactElement;
  props: PageProps;
}) => {
  const location = props.location;
  const position = getPathPosition(location.pathname);
  const pageClassName = PATHS_CLASSES[location.pathname];
  useLogVersion();
  return (
    <ScrollablePage className={pageClassName} position={position}>
      <>{element}</>
    </ScrollablePage>
  );
};

export default wrapPageElement;
