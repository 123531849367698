const MEDIA_BASE_URL = process.env.GATSBY_MEDIA_BASE_URL || "/media";

export const PHOTO_URLS = {
  BACKGROUND_CENTER: `${MEDIA_BASE_URL}/backgrounds/sfondo_centro.png`,
  BACKGROUND_TOP_LEFT: `${MEDIA_BASE_URL}/backgrounds/sfondo_alto_sx.png`,
  BACKGROUND_BOTTOM_RIGHT: `${MEDIA_BASE_URL}/backgrounds/sfondo_basso_dx.png`,
};

export function getProjectCoverURL(projectName: string) {
  return `${MEDIA_BASE_URL}/copertine/copertina_${projectName}.png`;
}

export function getPhotoURL(projectName: string, photoName: string) {
  return `${MEDIA_BASE_URL}/${projectName}/${photoName}.jpg`;
}
